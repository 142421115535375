import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

export default function DialogMap(props) {
   
    const { close, showedDialog, data, countrySelected } = props; 

    return (
        <div>
            <Dialog open={showedDialog} fullWidth>            
                <DialogContent>                    
                    <div>
                        <h1>{countrySelected?.name}</h1>
                        <div>
                            {(data) && parse(data?.text)}
                        </div>
                        <h2>Last data sent </h2>
                        <div>
                            Year : {data?.last_data}
                        </div>
                        <br/>
                        <Link to={`/country/${countrySelected?.id}`} className="view-more-link">View more details</Link>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>close()}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
