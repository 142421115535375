import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import HomeContainer from './pages/home/HomeContainer';
import AboutContainer from './pages/about/AboutContainer';
import CopyrightContainer from './pages/copyright/CopyrightContainer';
import OpenDataContainer from './pages/opendata/OpenDataContainer';
import CountryPage from './pages/about/CountryPage';
import UploadContainer from './pages/upload/UploadContainer';
import FilesContainer from './pages/files/FilesContainer';
import NotFoundCountainer from './pages/error/NotFoundContainer';

import './App.css'

function App() {
  return (
    <div className="App">     

      	<BrowserRouter basename={process.env.PUBLIC_URL}>
          	<MainLayout>

				<Routes>
					<Route path="/" 		    	element={<HomeContainer />}     	/>
					<Route path="/home"      		element={<HomeContainer />}     	/>
					<Route path="/about"  			element={<AboutContainer />}    	/>
					<Route path="/copyright"  		element={<CopyrightContainer />}    />
					<Route path="/opendata"  		element={<OpenDataContainer />}    	/>	
					<Route path="/country/:id" 		element={<CountryPage />} 			/>
					<Route path="/upload"			element={<UploadContainer />}		/>
					<Route path="/files"			element={<FilesContainer />}		/>
					<Route path="*" 				element={<NotFoundCountainer />} 	/>
				</Routes>

          	</MainLayout>
      	</BrowserRouter>

    </div>

  );
}

export default App;
