
import { getApiBaseUrl } from "../util";

export function getData(year) {
    
    const url = getApiBaseUrl() + '/api/chart03?sel_year=' + year;    
    return fetch(url,{method: 'get'});
}

export function getObject(measures) {

    let listCountries = [];
    let listAvgMonitored = []; 
    let listAvgExposed = [];
    let weightedMonitored = 0;
    let weightedExposed = 0;
    let totalNbW = 0;
    let totalExposedW = 0;

    measures.forEach(elem => {
        listCountries.push(elem.country);
        listAvgMonitored.push(elem.average_dose_monitored?.toFixed(2));
        listAvgExposed.push(elem.average_dose_exposed?.toFixed(2));
        weightedMonitored += elem.average_dose_monitored * elem.total_workers_number;
        weightedExposed += elem.average_dose_exposed * elem.exposed_w;
        totalNbW += elem.total_workers_number;
        totalExposedW += elem.exposed_w;
    });

    const avgMonitoredValue = weightedMonitored / totalNbW;
    const avgExposedValue = weightedExposed / totalExposedW;

    const data = {
        labels: listCountries,
        datasets: [
            {label: 'Avg dose / monitored workers (mSv)',data: listAvgMonitored,borderWidth: 1,},
            {label: 'Avg dose / measurably exposed workers (mSv)',data: listAvgExposed,borderWidth: 1,},
            {label: 'Avg value / monitored workers',data: [...Array(listCountries.length)].fill(avgMonitoredValue), type: 'line',borderWidth: 1,},
            {label: 'Avg value / measurably exposed workers', data: [...Array(listCountries.length)].fill(avgExposedValue),type: 'line', borderWidth: 1,},
        ],
    };

    const frame = {
        "Country": listCountries,
        "Avg dose / monitored workers (mSv)": listAvgMonitored,
        "Avg dose / measurably exposed workers (mSv)": listAvgExposed
    };    

    return {datas: data, frame: frame, exist: true};        
}