
function BoxOverview({ title, value, details}) {

    return (
        <div style={{padding: '15px'}}>
            <h1>{value}</h1>
            <h3>{title}</h3>
            <div>{details}</div>
        </div>
    );
}

export default BoxOverview;