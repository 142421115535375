import { getApiBaseUrl } from "../util";

export function getSalt() {

    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); 
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const salt = `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;

    return salt;
}

export function upload(file, fileName, token, salt, country, year, component) {

    const url = getApiBaseUrl() + '/api/upload';

    const formData = new FormData();
    formData.append('file', file);

    const headers = {
        'authorization': `Bearer ${token}`,
        'x-filename': fileName, 
        'x-country': country,
        'x-salt': salt,
        'x-year': year,
        'x-component': component
      };

    return fetch(url, {

        method: 'POST',
        headers: headers,
        body: formData,
    });
}

