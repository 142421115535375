
import { TextField } from '@mui/material';

export default function MyText(props) {

    const { label, value, handleChange, type } = props;

    return (
        
        <div style={{marginBottom: '10px'}}>
            <TextField
                label={label} variant="outlined" value={value} onChange={handleChange} type={type} 
                sx={{
                    width: '300px',
                    label: {color: 'primary.main'}
                }}
            />   
        </div>
   );
}