
import { getApiBaseUrl } from "../util";

export function findCountries() {
    const url = getApiBaseUrl() + '/api/countries';
    return fetch(url,{method: 'get'});
}

export function findYears() {
    const url = getApiBaseUrl() + '/api/years';
    return fetch(url,{method: 'get'});
}

export function findOccupations() {
    const url = getApiBaseUrl() + '/api/occupations';
    return fetch(url,{method: 'get'});
}
