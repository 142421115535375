import { styles } from './UploadStyle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as constant from '../../components/Constant';

export default function TableErreur(props) {

    const { errorsResponse } = props;

    return (        
        <div style={styles.container}>
            <div style={{color: 'red', marginBottom: '5px'}}>Errors found in file</div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} size="small">
                    <TableHead>
                        <TableRow>
                            <constant.StyledTableCell style={{ width: '10%' }}>Line</constant.StyledTableCell>
                            <constant.StyledTableCell style={{ width: '90%' }}>Error</constant.StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {errorsResponse?.map((error, i) => (
                            <constant.StyledTableRow key={i}>
                                <constant.StyledTableCell>{error.ligne}</constant.StyledTableCell>
                                <constant.StyledTableCell>{error.event}</constant.StyledTableCell>
                            </constant.StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}