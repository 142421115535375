import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, LineController, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, LineController, Title, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
    scales: {
        y: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
                display: true,
                text: 'Workers',
            },
        },
        y1: {
            type: 'linear',
            display: true,
            position: 'right',
            // grid line settings
            grid: {
                drawOnChartArea: false, 
            },
            title: {
                display: true,
                text: 'Average dose (mSv)',
            }
        },
    },
};


function Chart06(props) {

    const { datas } = props; 

    return (
        (datas) && (datas.exist) &&
        <Bar data={datas.datas} options={options} />
    );
}

export default Chart06;