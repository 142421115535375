import React, { useState } from 'react';
import { Button, TextField  } from '@mui/material';
import * as uploadService from '../../services/upload/UploadService';
import * as fileService from '../../services/files/FilesService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styles } from './FilesStyle';
import * as constant from '../../components/Constant';
import SnackBar from '../../components/SnackBar';

export default function FilesContainer() {

    const [password, setPassword] = useState("");
    const [files, setFiles] = useState([]);
    const [elementSnack, setElementSnack] = useState({show: false, message: '', severity: 'success'});
    const [show, setShow] = useState(false);

    const { sha256 } = require('js-sha256');
    const dayjs = require('dayjs'); 

    const handleSubmit = async (event) => {
     
        event.preventDefault();

        setFiles([]);

        const salt = uploadService.getSalt();

        const hash = sha256.create();
        hash.update(password + salt);
        const value = hash.hex();

        fileService.findAll(value, salt)
            .then((response) => {

                if (!response.ok) {

                    if (response.status === 401) {
                        setElementSnack({show: true, message: "Error authentication", severity: 'error'});    
                    } else {
                        setElementSnack({show: true, message: "Erreur !", severity: 'error'});
                    }
                    return -1;
                }                

                return response.json();
            })
            .then((datas) => {
                if (datas !== -1) {
                    setFiles(datas);
                    setShow(true);
                }
            })
            .catch((error) => {
                
                setElementSnack({show: true, message: "Erreur !", severity: 'error'});
                console.log("error", error);
            });        

    };

    return (
        
        <div style={styles.main}>
            <SnackBar handleClose={()=>setElementSnack({show: false})} elementSnack={elementSnack} />

            <div style={styles.container}>
                
                <div style={{marginBottom: '10px'}}>
                    <TextField
                        label="PASSWORD" variant="outlined" value={password} onChange={(event)=> setPassword(event.target.value)} type="password" 
                        sx={{
                            width: '300px',
                            label: {color: 'primary.main'}
                        }}
                    />   
                </div>
                <br/>
                <div>
                    <Button variant="contained" color="primary" onClick={()=> window.location.reload()}>
                        Reset
                    </Button>
                    &nbsp;
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </div>
            <br/>
            {(files && show) && 
                <TableContainer component={Paper}>
                    <Table sx={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <constant.StyledTableCell style={{ width: '5%' }}>Id</constant.StyledTableCell>
                                <constant.StyledTableCell style={{ width: '30%' }}>Name</constant.StyledTableCell>
                                <constant.StyledTableCell style={{ width: '10%' }}>Year</constant.StyledTableCell>
                                <constant.StyledTableCell style={{ width: '15%' }}>Country</constant.StyledTableCell>
                                <constant.StyledTableCell style={{ width: '25%' }}>Component</constant.StyledTableCell>
                                <constant.StyledTableCell style={{ width: '15%' }}>Date</constant.StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files?.map((file, i) => (
                                <constant.StyledTableRow key={i}>
                                    <constant.StyledTableCell>{file.id}</constant.StyledTableCell>
                                    <constant.StyledTableCell>{file.name}</constant.StyledTableCell>
                                    <constant.StyledTableCell>{file.year}</constant.StyledTableCell>
                                    <constant.StyledTableCell>{file.country}</constant.StyledTableCell>
                                    <constant.StyledTableCell>{file.component}</constant.StyledTableCell>
                                    <constant.StyledTableCell>{dayjs(file.date_upload).format("DD/MM/YYYY - HH:mm:ss")}</constant.StyledTableCell>
                                </constant.StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
    );
}