
export default function NotFoundCountainer() {
	
	const containerStyle = {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: "80vh",
		textAlign: "center",
		backgroundColor: "#f5f5f5",
	};

	const errorCodeStyle = {
		fontSize: "60px",
		fontWeight: "bolder",
		marginBottom: "20px", 
	};

	const errorMessageStyle = {
		fontSize: "20px",
		marginBottom: "10px", 
	};

	const errorDescriptionStyle = {
		fontSize: "12px",
		color: "#555", 
	};

  	return (
		<div style={containerStyle}>
			<div style={errorCodeStyle}>404</div>
			<div style={errorMessageStyle}>Not Found</div>
			<div style={errorDescriptionStyle}>
				The resource requested could not be found on this server
			</div>
		</div>
	);
}
