export const csvmaker = (data) => {

    let csvRows = [];
    const headers = Object.keys(data);
    headers.forEach(element => {
        let values = element + "," + data[element];
        csvRows.push(values);
    });

    return csvRows.join('\n');
}

export const download = (data, fileName) => {

    const blob = new Blob([data], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName 
    a.click();
}