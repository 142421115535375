import { getApiBaseUrl } from "../util";

export function getDataMonitored(country, year) {
    
    const url = getApiBaseUrl() + '/api/chart0101?sel_country=' + country + '&sel_year=' + year;
    return fetch(url,{method: 'get'});
}

export function getDataTotalHsv(country, year) {

    const url = getApiBaseUrl() + '/api/chart0102?sel_country=' + country + '&sel_year=' + year;
    return fetch(url,{method: 'get'});
}

export function getObjectChart101(measures) {

    const listFields = [];
    const listMonitored = [];

    measures.forEach(elem => {
        listFields.push(elem.sector);
        listMonitored.push(elem.total);
    });

    const frame = {
        "Field": listFields,
        "Monitored workers": listMonitored,
        "Collective dose (man.Sv)": []              // sera remplie par chart102
    };

    const data = {
		labels: listFields, 
		datasets: [
			{
                label: '# Workers',
                data: listMonitored,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.4)', //rouge
                    'rgba(54, 162, 235, 0.4)', //bleu
                    'rgba(75, 220, 180, 0.4)', //vert
                    'rgba(255, 206, 86, 0.4)', //jaune
                    'rgba(201, 203, 207, 0.4)', //gris
                    'rgba(153, 102, 255, 0.4)', //violet
                    'rgba(255, 159, 64, 0.4)', //orange
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(75, 220, 180, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(201, 203, 207, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
			},
		],
	};

    return {datas: data, frame: frame, exist: true};
}

export function getObjectChart102(measures) {

    const listFields = [];
    const listTotalHsv = [];

    measures.forEach(elem => {
        listFields.push(elem.sector);
        listTotalHsv.push(elem.total_homme_sv?.toFixed(3));
    });

    const frame = {
        "Collective dose (man.Sv)": listTotalHsv
    };

	const data = {
		labels: listFields,
		datasets: [
			{
				label: '# Workers',
				data: listTotalHsv,
				backgroundColor: [
					'rgba(255, 99, 132, 0.4)', //rouge
					'rgba(54, 162, 235, 0.4)', //bleu
					'rgba(75, 220, 180, 0.4)', //vert
					'rgba(255, 206, 86, 0.4)', //jaune
					'rgba(201, 203, 207, 0.4)', //gris
					'rgba(153, 102, 255, 0.4)', //violet
					'rgba(255, 159, 64, 0.4)', //orange
				],
				borderColor: [
					'rgba(255, 99, 132, 1)',
					'rgba(54, 162, 235, 1)',
					'rgba(75, 220, 180, 1)',
					'rgba(255, 206, 86, 1)',
					'rgba(201, 203, 207, 1)',
					'rgba(153, 102, 255, 1)',
					'rgba(255, 159, 64, 1)',
				],
				borderWidth: 1,
			},
		],
	};

    return {datas: data, frame: frame, exist: true};
 
}