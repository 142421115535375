import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Link } from "react-router-dom";
import Nav from './Nav'
import '../styles/Header.css';

function Header(props) {

    return (
        <Box
            mb={3} 
            gap={4}
            p={2}
            className = "header"
        >
            <Grid container spacing={1} mx={5} direction={{ xs: "column", sm: "row" }}>
                <Grid  xs={12} sm={1} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Link to="/home">
                        <img src="pictures/small_esorex.jpg" alt="logo" />
                    </Link>
                </Grid>
                <Grid  xs={12} sm={6} sx={{ display: 'flex', alignItems: 'left' }}>
                    <div className="large-text">ESOREX : European Platform for Occupational Radiation Exposure</div>
                </Grid>
                <Grid  xs={12} sm={4}>
                    <Nav props={props} />
                </Grid>

            </Grid>
        </Box>
    );
}
export default Header