import '../../styles/Container.css'

function CopyrightContainer() {

  	return (
		<div className='main'>
		
			<h1>About this website</h1>
			
			<h2>Publisher</h2>
			<p>				
				Autorité de Sureté Nucléaire et de Radioprotection (<b>ASNR</b>)<br/><br/>
				15 rue Louis-Lejeune <br/>
				92120 Montrouge<br/>
				France<br/>
				Tel. : 01.58.35.88.88  
			</p>

			<h2>Hosting</h2>
			<p>
				Servers are located in France (Gravelines) OVH Cloud SIRET : 42476141900045<br/><br/>
				2 rue Kellermann<br/>
				59100 Roubaix<br/>
				France<br/>
			</p>

			<h2>Design & Development</h2>
			<p>
				ASNR <br/>
				<a href="https://www.asnr.fr/">https://www.asnr.fr/</a><br/><br/>

				© Copyright ASNR <br/>

				Dataset is published in opendata and licensed <br/> 
				under "Licence ouverte 2.0 / open Licence 2.0"  (<a href='https://www.etalab.gouv.fr/wp-content/uploads/2018/11/open-licence.pdf'>https://www.etalab.gouv.fr/wp-content/uploads/2018/11/open-licence.pdf</a>)

			</p>
	
		</div>
	);
}

export default CopyrightContainer;