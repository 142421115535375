import { getApiBaseUrl } from "../util";

export function getData(country) {

    const url = getApiBaseUrl() + '/api/loadgeojson?country=' + country;

    return fetch(url,
        {
            method: 'get'
        });
}