
import { getApiBaseUrl } from "../util";

export function findAll() {

    const url = getApiBaseUrl() + '/api/overview/workers';

    return fetch(url,
    {
        method: 'get'
    });
}