
import { getApiBaseUrl } from "../util";

export function getData(country) {

    const url = getApiBaseUrl() + '/api/chart04?sel_country=' + country;
    return fetch(url,{method: 'get'});
}

export function getObject(measures) {

    const listYears = [];
    const listMonitored = [];
    const listExposed = [];
    const listAbove1mSV = [];

    measures.forEach(elem => {
        listYears.push(elem.year);
        listMonitored.push(elem.total);
        listExposed.push(elem.exposed_w);
        listAbove1mSV.push(elem.above1_msv);
    });

    var frame = {
        "Country": listYears,
        "Monitored workers": listMonitored,
        "Measurably exposed workers": listExposed,
        "Workers above 1 mSv": listAbove1mSV
    };

    const data = {
        labels: listYears,
        datasets: [
            {
                label: 'Monitored workers',
                data: listMonitored,
                borderWidth: 1,
            },
            {
                label: 'Measurably exposed workers',
                data: listExposed,
                borderWidth: 1,
            },
            {
                label: 'Workers above 1 mSv',
                data: listAbove1mSV,
                borderWidth: 1,
            },
        ],
    };

    return {datas: data, frame: frame, exist: true};    
}