import * as opendata from '../../services/opendata/opendataService';
import { Button } from '@mui/material';
import { styles } from './OpenDataStyle';

export default function OpenDataComponent(props) {

    const { title, components } = props;

    const downLoadCSV = (idComponent) => {
    	opendata.findAll(idComponent)
  	};

    return (
        
        <div style={styles.container}>
            <h3>{title}</h3>
            <Button variant="outlined"  onClick={() => downLoadCSV(components.id)} style={styles.button}>
                Download CSV
            </Button>
        </div>
    );
}