import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, Colors } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, Title, Colors);

const options = {
	responsive: true, 
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'bottom', 
		},
		title: {
			display: true,
			text: 'Monitored workers per field',
		},
	},
};

function Chart101(props) {

	const { datas } = props; 
  	return (

		(datas.datas1) && (datas.exist1) &&
		<div style={{ aspectRatio: 1 }}><Pie data={datas.datas1} options={options} /></div>
	);
}
export default Chart101;