import React, { useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import * as uploadService from '../../services/upload/UploadService';
import * as countryService from '../../services/country/countryService';
import SnackBar from '../../components/SnackBar';
import MyText from './MyText';
import { styles } from './UploadStyle';
import { COMPONENTS }  from '../../services/opendata/opendataService';
import TableErreur from './TableErreur';

export default function UploadComponent() {

    const [file, setFile] = useState(null);
    const [password, setPassword] = useState("");
    const [countries, setContries] = useState([]);
    const [country, setCountry] = useState("");
    const [year, setYear] = useState("");
    const [component, setComponent] = useState("");
    const [fileName, setFileName] = useState("");
    const [errorsResponse, setErrorsResponse] = useState([]);
    const [elementSnack, setElementSnack] = useState({show: false, message: '', severity: 'success'});
    const { sha256 } = require('js-sha256');

    useEffect(() => {

        countryService.findAll()
            .then((response) => response.json())
            .then((data) => {
                setContries(data);
            })
            .catch((err) => {
                console.error("En Erreur", err);
            });

    }, []);


    const handleSubmit = async (event) => {
     
        event.preventDefault();

        setErrorsResponse([]);  // vidage des erreurs
    
        if (!validateDatas()) return;
    
        const formData = new FormData();
        formData.append('file', file);
    
        const salt = uploadService.getSalt();

        const hash = sha256.create();
        hash.update(password + salt);
        const value = hash.hex();

        uploadService.upload(file, fileName, value, salt, country, year, component)
            .then((response) => {

                if (!response.ok) {

                    if (response.status === 401) {
                        setElementSnack({show: true, message: "Error authentication", severity: 'error'});    
                    } else {

                        response.json().then((errorData) => {

                            setElementSnack({show: true, message: errorData.detail.message, severity: 'error'});
                            setErrorsResponse(errorData.detail.erreurs);
                        });
                    }
                    return -1;
                }                

                return response.json();
            })
            .then((data) => {
                if (data !== -1) {
                    console.log("data:", data);
                    setElementSnack({show: true, message: "ok", severity: 'success'});
                }
            })
            .catch((error) => {
                setElementSnack({show: true, message: "HTTP error !!", severity: 'error'});
            });
    };
    
    const handleFileChange = (event) => {
        const f = event.target.files[0];
        setFile(f);
        setFileName(f.name);
    };

	const handleClose = () => {
		setElementSnack({show: false});
	}

    const validateDatas = () => {

        if (!password || password ==="") { setElementSnack({show: true, message: "Select password", severity: 'error'}); return false; }
        if (!country || country === '') { setElementSnack({show: true, message: "Select country", severity: 'error'}); return false; }
        if (!year || year === '') { setElementSnack({show: true, message: "Select year", severity: 'error'}); return false; }
        if (!isValidYear()) { setElementSnack({show: true, message: "Year is not valid", severity: 'error'}); return false; }
        if (!component || component === '') { setElementSnack({show: true, message: "Select component", severity: 'error'}); return false; }
        if (!file) { setElementSnack({show: true, message: "Select file", severity: 'error'}); return false; }
        if (fileName.length < 5 || fileName.slice(-4) !== ".csv") { setElementSnack({show: true, message: "bad format", severity: 'error'}); return false; }

        return true;
    }

    const isValidYear = () => {
        const regex = /^[0-9]+$/; 
        if (regex.test(year)) {
          const number = parseInt(year, 10); 
          return number >= 2000 && number <= 2100; 
        }
        return false;
    };

    return (
        
        <div className='main'>
            <SnackBar handleClose={handleClose} elementSnack={elementSnack} />
            <div style={styles.container}>
                
                <MyText label="PASSWORD" value={password} type="password" handleChange={(event)=> setPassword(event.target.value)} />
                <FormControl sx={{marginBottom: '10px'}}>
                    <InputLabel sx={{color: 'primary.main'}} >COUNTRY</InputLabel>
                    <Select value={country} label="COUNTRY" onChange={(event) => setCountry(event.target.value)}  sx={{width: '300px'}}>
                        {
                            countries.map((country) => <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>                    
                <MyText label="YEAR" value={year} type="text" handleChange={(event)=> setYear(event.target.value)} />
                <FormControl sx={{marginBottom: '10px'}}>
                    <InputLabel sx={{color: 'primary.main'}} >COMPONENT</InputLabel>
                    <Select value={component} label="COMPONENT" onChange={(event) => setComponent(event.target.value)}  sx={{width: '300px'}}>
                        <MenuItem value={COMPONENTS.TYPE_WHOLE_BODY.id}>Whole body</MenuItem>
                        <MenuItem value={COMPONENTS.TYPE_EYE_LENS.id}>Eye's lens</MenuItem>
                        <MenuItem value={COMPONENTS.TYPE_SKIN.id}>Skin</MenuItem>
                        <MenuItem value={COMPONENTS.TYPE_EXTREMITIES.id}>Extremities</MenuItem>
                    </Select>
                </FormControl>

                <div style={{ marginBottom: '10px' }}>
                    <Button variant="outlined" component="label" color="primary" sx={styles.button} >
                        Select File
                        <input type="file" hidden onChange={handleFileChange} />
                    </Button>
                    {(fileName !== "") &&
                        <span style={{paddingLeft: '20px'}}>( selected file : {fileName} )</span>
                    }
                </div>
                <br/>
                <div>
                    <Button variant="contained" color="primary" onClick={()=> window.location.reload()}>
                        Reset
                    </Button>
                    &nbsp;
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </div>
            {(errorsResponse) && (errorsResponse.length > 0) &&
                <TableErreur errorsResponse={errorsResponse} />
            }
        </div>
    );
}