
export const styles = {
    container: {
        border: '1px solid black',
        padding: '10px',
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    button: {
        padding: '10px 20px',
        cursor: 'pointer',
    },
}; 