import '../../styles/Container.css';
import OpenDataComponent from './OpenDataComponent';
import { COMPONENTS }  from '../../services/opendata/opendataService';

function OpenDataContainer() {

  	return (

		<div className='main'>

			<h2>Esorex Project Objective</h2>
			<p>
				One of the key objectives of the Esorex platform is to provide public access to all the collected dosimetry data from various countries.
			</p>

			<OpenDataComponent title="Whole body"   components={COMPONENTS.TYPE_WHOLE_BODY}     />
			<OpenDataComponent title="Eye's lens"   components={COMPONENTS.TYPE_EYE_LENS} 	    />
			<OpenDataComponent title="Skin"         components={COMPONENTS.TYPE_SKIN} 		    />
			<OpenDataComponent title="Extremities"  components={COMPONENTS.TYPE_EXTREMITIES}    />

		</div>
  );
}

export default OpenDataContainer;
