import { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function BoxChart(props) {

    const { title, showSelectCountry, showSelectYear, showSelectOccupation, years, countries, occupations, action, actionDownload} = props; 

    const [valueSelected, setValueSelected] = useState({year: '', country: '', occupation: ''});

    const isNonEmptyArray = (arr) => Array.isArray(arr) && arr.length > 0;

    useEffect(() => {
        //if (years && countries && occupations) {
        if (years?.data && isNonEmptyArray(countries) && isNonEmptyArray(occupations)) {
            //if (years.data) {
            setValueSelected({year: years.data[0].year, country: countries[0].id, occupation: occupations[0].id});
            //}
        }
    }, [years, countries, occupations]); 

    const changeValue = (value, typeValue) => {

        let clone = { ...valueSelected }
        clone[typeValue] = value;
        setValueSelected(clone);
        action(clone);
    }

    const styles = { 
        inputLabel : {
            backgroundColor: 'white', 
            paddingLeft: '5px', 
            paddingRight: '5px', 
            color: 'primary.main'
        },
    };

    return (
        <Paper sx={{ padding: 2, marginTop: 2 }}>
            <div >
                <h2>{title}</h2>
            </div>

            <Grid container spacing={1}>
                
                {showSelectCountry &&
                    <Grid sm={2}>
                      <FormControl fullWidth size='small'>
                            <InputLabel sx={styles.inputLabel}>Country</InputLabel>
                            <Select sx={{ fontSize: '0.8rem', padding: '4px' }}
                                    value={valueSelected.country}
                                    onChange={(event)=>changeValue(event.target.value, 'country')}
                            >
                                {
                                    (countries) &&
                                    (countries.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {showSelectYear && years && years.data &&
                    <Grid sm={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel sx={styles.inputLabel}>Year</InputLabel>
                            <Select sx={{ fontSize: '0.8rem', padding: '4px' }}
                                    value={valueSelected.year}
                                    onChange={(event)=>changeValue(event.target.value, 'year')}
                            >
                                {
                                    (years) &&
                                    (years.data.map(o => <MenuItem key={o.year} value={o.year}>{o.year}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {showSelectOccupation &&
                    <Grid sm={4}>
                        <FormControl fullWidth size='small'>
                            <InputLabel sx={styles.inputLabel}>Occupation</InputLabel>
                            <Select sx={{ fontSize: '0.8rem', padding: '4px' }}
                                    value={valueSelected.occupation}
                                    onChange={(event)=>changeValue(event.target.value, 'occupation')}
                            >
                                {
                                    (occupations) &&
                                    (occupations.map(o => <MenuItem key={o.id} value={o.id}>{o.label}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                }             
                <Grid sm={2}>
                    <img src="pictures/icon_download.png" alt="icon download" title="download chart data" style={{cursor: 'pointer'}} onClick={()=>actionDownload()} />
                </Grid>
            </Grid>

            {props.children}

        </Paper>
    );
}

export default BoxChart;