import Map from './Map';
import '../../styles/Container.css';

export default function AboutContainer() {

    return (
        <div className='main'>

            <div className='justify'>

                <h2>Esorex history</h2>
                <p>The European Commission initiated in 1997 the European Study on Occupational Radiation Exposure (ESOREX) which objectives were:</p>
                <div>
                    <ul>
                        <li>to provide the European Commission and the national competent radiation protection authorities with reliable information on how personal radiation monitoring,
                            reporting and recording of dosimetric results is structured in European countries;
                        </li> 
                        <br />
                        <li>to collect reliable and directly comparable data on individual (levels of individual personal radiation doses to workers) and collective exposure in all occupational
                            sectors where classified workers are employed (nuclear fuel cycle, medical sector, industry in general, research and education, and natural sources), and the trends and
                            developments of these doses over a period of several years.
                        </li>
                    </ul>
                    <br />
                    <div>
                        The last ESOREX symposium, involving representatives from organizations responsible, in all European Union Member States, Associated States and Candidate States,
                        for the collection of dosimetric data and information on occupational radiation monitoring and exposure, took place in Prague (Czech Republic) in 2010. 
                    </div>
                    
                </div>
                <br />
                <p>
                    As a result, it was proposed to establish a European Platform for Occupational Radiation Exposures for EU countries, associated countries and voluntary countries in
                    which representatives from national dose registries and from dosimetry services can discuss emerging issues, assess dose trends and exchange experience.
                </p>

            </div>

            <Map />

        </div>
    );
}
