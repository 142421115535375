import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Nav() {
	const [activeTab, setActiveTab] = useState(0); // Onglet actif par défaut
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		// Mise à jour de l'onglet actif en fonction du chemin
		if (location.pathname === "/about") {
			setActiveTab(1); // "About"
		} else if (location.pathname === "/opendata") {
			setActiveTab(2); // "Open Data"
		} else {
			setActiveTab(0); // "Home" par défaut
		}
	}, [location.pathname]);

	const goHome = () => {
		navigate("/home");
	};
	const goAbout = () => {
		navigate("/about");
	};
	const goOpenData = () => {
		navigate("/opendata");
	};

	return (
		<Tabs
			value={activeTab} // Onglet sélectionné
			aria-label="Tabs where selection follows focus"
			selectionFollowsFocus
			centered

            
            sx={{
                display: "flex",  // Affiche les éléments en ligne sur les grands écrans
                flexDirection: { xs: "column", sm: "row" }, // Sur mobile (xs), les onglets sont empilés; sur grands écrans (sm et plus), ils sont horizontaux
                alignItems: "center"
            }}

		>
			<Tab label="Home" onClick={goHome} />
			<Tab label="About" onClick={goAbout} />
			<Tab label="Open Data" onClick={goOpenData} />
		</Tabs>
	);
}

export default Nav;
