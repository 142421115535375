
import { Tooltip } from 'react-leaflet';

export default function MapToolTip(props) {

    const { url, altText, country } = props;

    return (
        <Tooltip sticky>
            <img src={url} alt={altText} /> 
            <br />
            Country: {country.name} 
            <br />
            (click to show infos)
        </Tooltip>
   );
}