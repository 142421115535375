import Box from '@mui/material/Box'; 
import { Link } from 'react-router-dom'; 
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import '../styles/Header.css';

function Footer() {

    return( 
        <Box
            mt={3}  
            gap={4}
            p={2}
            className="header"
            sx={{ textAlign: 'center' }}
        >
            <Grid container spacing={1} mx={5} >
                <Grid xs={6} sx={{ textAlign: 'right', paddingRight:'30px' }}>
                    <Link to="/copyright">Copyright</Link>
                </Grid>
                <Grid xs={6} sx={{ textAlign: 'left', paddingLeft:'30px' }}>
                    <div title='esorex2@asnr.fr'><a style={{textDecoration: 'underline', color: 'blue'}} href="mailto:esorex2@asnr.fr">Contact us</a></div>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Footer;

