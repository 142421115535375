import { getApiBaseUrl } from "../util";

export function getData(country) {

    const url = getApiBaseUrl() + '/api/mapdata?sel_country=' + country;

    return fetch(url,
        {
            method: 'get'
        });
}