
import { getApiBaseUrl } from "../util";

export function getData(country, occupation) {

    const url = getApiBaseUrl() + '/api/chart06?sel_country=' + country + '&sel_occupation=' + occupation;
    return fetch(url,{method: 'get'});
}

export function getObject(measures) {

    const listYears = [];
    const listMonitored = [];
    const listExposed = [];
    const listAvgMonitored = [];
    const listAvgExposed = [];
    
    measures.forEach(elem => {
        listYears.push(elem.year);
        listMonitored.push(elem.total);
        listExposed.push(elem.exposed_w);
        listAvgMonitored.push(elem.avg_dose_monitored?.toFixed(4));
        listAvgExposed.push(elem.avg_dose_exposed?.toFixed(4));
    });

    const frame = {
        "Year": listYears,
        "Monitored workers": listMonitored,
        "Measurably exposed workers": listExposed,
        "Avg dose / monitored workers (mSv)": listAvgMonitored,
        "Avg dose / measurably exposed workers (mSv)": listAvgExposed
    };
    
    const data = {
        labels: listYears,
        datasets: [
            {
                label: 'Monitored workers',
                data: listMonitored,
                borderWidth: 1,
                yAxisID: 'y',
            },
            {
                label: 'Measurably exposed workers',
                data: listExposed,
                borderWidth: 1,
                yAxisID: 'y',
            },
            {
                label: 'Avg dose / monitored workers (mSv)',
                data: listAvgMonitored,
                borderWidth: 1,
                type: 'line',
                yAxisID: 'y1',
            },
            {
                label: 'Avg dose / measurably exposed workers (mSv)',
                data: listAvgExposed,
                borderWidth: 1,
                type: 'line',
                yAxisID: 'y1',
            },

        ],
    };

    return {datas: data, frame: frame, exist: true}; 
}