
import { getApiBaseUrl } from "../util";

export function getData(country) {
    
    const url = getApiBaseUrl() + '/api/chart05?sel_country=' + country;
    return fetch(url,{method: 'get'});
}

export function getObject(measures) {

    const listYears = [];
    const listAvgMonitored = [];
    const listAvgExposed = [];

    measures.forEach(elem => {
        listYears.push(elem.year);
        listAvgMonitored.push(elem.average_dose_monitored?.toFixed(2));
        listAvgExposed.push(elem.average_dose_exposed?.toFixed(2));
    });

    const frame = {
        "Year": listYears,
        "Avg dose / monitored workers (mSv)": listAvgMonitored,
        "Avg dose / measurably exposed workers (mSv)": listAvgExposed
    };

    const data = {
        labels: listYears,
        datasets: [
            {
                label: 'Avg dose / monitored workers (mSv)',
                data: listAvgMonitored,
                borderWidth: 1,
            },
            {
                label: 'Avg dose / measurably exposed workers (mSv)',
                data: listAvgExposed,
                borderWidth: 1,
            },
        ],
    };

    return {datas: data, frame: frame, exist: true}; 
}