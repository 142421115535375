
import { getApiBaseUrl } from "../util";

export const COMPONENTS = {
  TYPE_WHOLE_BODY:  { id: 1, label: "Whole_body" },
  TYPE_EYE_LENS:    { id: 3, label: "Lens" },
  TYPE_SKIN:        { id: 4, label: "Skin" },
  TYPE_EXTREMITIES: { id: 2, label: "Extremities" }
};

export const findAll = async (componentId) => {

  	try {

    	const obj = Object.values(COMPONENTS).find((item) => item.id === componentId);
      	const suffix = obj ? obj.label : "default";

      	const url_query = getApiBaseUrl() + `/api/opendata?component=${componentId}&format=csv`;
      	const response = await fetch(url_query);

		if (!response.ok) {
        	throw new Error('Network response was not ok');
    	}

    	const csvData = await response.text();

		// Création d'un lien de téléchargement pour le fichier CSV
		const blob = new Blob([csvData], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);

		const a = document.createElement('a');
		a.href = url;

		// Utilisation du suffixe basé sur le componentId pour nommer le fichier
		a.download = `Esorex_${suffix}.csv`;

		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);

  } catch (error) {

    	console.error('Failed to download CSV:', error);
  }
};
