import "leaflet/dist/leaflet.css"; 
import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import '../../styles/Map.css';
import * as valuesSelectService from '../../services/initialization/valuesSelectService';
import * as loadJsonData from '../../services/autres/loadGeojsonService';
import * as mapData from '../../services/autres/mapDataService';
import MapToolTip from "./MapToolTip";
import DialogMap from "./DialogMap";

function Map() {

	const [countries, setCountries] = useState([]);
	const [geoJsonData, setGeoJsonData] = useState({});
	const [popupData, setPopupData] = useState({});
	const [countrySelected, setCountrySelected] = useState(null);
	const [showedDialog, setShowedDialog] = useState(false);
	const [data, setData] = useState(null);

	useEffect(() => {
		async function fetchCountries() {
			try {
				const response = await valuesSelectService.findCountries();
				const data = await response.json();
				setCountries(data);
			} catch (error) {
				console.error("Error fetching countries:", error);
			}
		}

		fetchCountries();
	}, []);

  	useEffect(() => {

    	async function fetchGeoJsonData(countryCode) {
      		try {
        		const response = await loadJsonData.getData(countryCode);
        		const data = await response.json();
        		setGeoJsonData(oldVar => ({ ...oldVar, [countryCode]: data }));
      		} catch (error) {
        		console.error(`Error fetching GeoJSON data for ${countryCode}:`, error);
      		}
    	}

    	async function fetchPopupData(country_id) {
			try {
				const response = await mapData.getData(country_id);
				const data = await response.json();
				
				// Mise à jour de l'état popupData avec les nouvelles données
				setPopupData(oldVar => ({
				...oldVar,
				[country_id]: data // Ajout ou mise à jour de l'entrée pour le pays sélectionné
				}));
			} catch (error) {
				console.error("Error fetching popup data:", error);
			}
		}

    	countries.forEach(country => {
      		fetchGeoJsonData(country.code);
      		fetchPopupData(country.id);
    	});

  	}, [countries]);
  	
	const clickCountry = (country) => {

		setCountrySelected(country);
		setData(popupData[country.id]);
		setShowedDialog(true);		
	}

  return (
    <>
        <DialogMap  showedDialog={showedDialog} 
                    close={()=>setShowedDialog(false)}
					data={data} 
					countrySelected={countrySelected}
		/>

      	<h2>Explore the Map for details on involved countries</h2>
      	<p>
        	Click on any country on the map below to view more detailed information about occupational radiation exposure in that country.
      	</p>

		<MapContainer center={[55, 16]} zoom={4} minZoom={4} maxZoom={4} dragging={false} style={{ height: '70vh', position: 'relative' }}>

			<TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />

			{	countries.map(country => {
			
					const geoJson = geoJsonData[country.code];
					const url = `pictures/flags/${country.code}.png`;
					const altText = `flag ${country.name}`;
					const myStyle = {color: country.color, weight: 2, opacity: 0.8, fillColor: country.color, fillOpacity: 0.6};

					return geoJson ? (
						<GeoJSON 
							key={country.code} 
							data={geoJson} 
							style={myStyle}
							eventHandlers={{
								click: () => clickCountry(country) 
							}}
						>
							<MapToolTip url={url} altText={altText} country={country} />
						</GeoJSON>
					) : null;
				})
			}
		</MapContainer>

    </>
  );
}

export default Map;
