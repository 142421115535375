import Grid from '@mui/material/Grid';
import BoxOverview from './BoxOverview';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';

import * as valuesSelectService from '../../services/initialization/valuesSelectService';
import * as countriesInvolved from '../../services/initialization/countriesInvolvedService';
import * as workersInvolved from '../../services/initialization/nbWorkersInvolvedService';

function Overview() {

    const [nbCountries, setNbCountries] = useState(12);
    const [period, setPeriod] = useState("2010-2022");
    const [nbWorkers, setNbWorkers] = useState({ year: 2010, max_value: "1 M" });

    useEffect(() => {
        //appel au service qui récupère le nombre de pays
        countriesInvolved.findAll()
            .then((response) => response.json())
            .then((data) => {
                setNbCountries(data.total);
            })
            .catch((err) => {
                console.error("En Erreur", err);
            });

        //appel au service qui renvoie la liste des années    
        valuesSelectService.findYears()
            .then((response) => response.json())
            .then((data) => {
                if (data.length > 0) {
                    let varPeriod = data[data.length - 1].year;
                    varPeriod += " - ";
                    varPeriod += data[0].year;
                    setPeriod(varPeriod);
                }
            })
            .catch((err) => {
                console.error("En Erreur", err);
            });

        //appel au service qui compte le total des travailleurs exposés
        workersInvolved.findAll()
            .then((response) => response.json())
            .then((data) => {
                const formattedValue = parseInt(data.total, 10).toLocaleString();  // Conversion et formatage
                setNbWorkers({ year: data.year, max_value: formattedValue });
            })
            .catch((err) => {
                console.error("En Erreur", err);
            });

    }, []);

    const monitored_title = "Monitored workers (year " + nbWorkers.year + ")";

    return (
        <div>
            <h1>Overview</h1>
            <Paper elevation={3}>
                <Grid container spacing={1} >
                    <Grid item xs={12} sm={4}>
                        <BoxOverview title="Countries involved" value={nbCountries} details="Countries that have submitted annual data at least once" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <BoxOverview title="Period" value={period} details="Years for which dosimetry data is available"/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <BoxOverview title={monitored_title} value={nbWorkers.max_value} details=" Highest recorded number of workers monitored annually and the corresponding year"/>
                    </Grid>
    
                </Grid>
            </Paper>
        </div>
    );
}
export default Overview;