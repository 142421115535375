import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'bottom', 		
		},
		title: {
			display: true,
			text: 'Collective dose per field (man.Sv)',
		},
	},
};

function Chart102(props) {

	const { datas } = props; 
	
  	return (

		(datas.datas2) && (datas.exist2) &&
		<div style={{ aspectRatio: 1 }}><Pie data={datas.datas2} options={options} /></div>
	);
}
export default Chart102;