import Overview from './Overview';
import Charts from './Charts';
import '../../styles/Container.css'

function HomeContainer() {

  	return (
		<div className='main'>
			
			<div className="large-text">
				The main objectives of the ESOREX Platform are to establish and maintain an overview of national arrangements for occupational radiation in Europe, facilitate easy information and experience exchange between experts in occupational radiation exposure, and provide a basis for the evaluation and assessment of occupational radiation exposure data.
			</div>
			
			<Overview />
			<Charts />

		</div>
	);
}

export default HomeContainer;