import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBar(props) {

    const { handleClose, elementSnack } = props; 

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={elementSnack.show} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={elementSnack.severity} sx={{ width: '100%' }}>
                    {elementSnack.message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
