import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        }
    },
    scales: {
        y: {
            beginAtZero: true,
        },
    },
};

function Chart02(props) {

    const { datas } = props; 

    return (

        (datas) && (datas.exist) &&
        <Bar data={datas.datas} options={options} />
     )
}

export default Chart02;