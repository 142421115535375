import { getApiBaseUrl } from "../util";

export function getData(year) {

    const url = getApiBaseUrl() + '/api/chart02?sel_year=' + year;
    return fetch(url,{method: 'get'});
}

export function getObject(measures) {

    const labelsData = [];
    const monitoredData = [];
    const exposedData = [];
    const above1Data = [];                

    if (measures) {
        measures.forEach(elem => {
            labelsData.push(elem.country);
            monitoredData.push(elem.total);
            exposedData.push(elem.exposed_w);
            above1Data.push(elem.above1_msv);
        });                
    }

    const data = {
        labels: labelsData,
        datasets: [ {label: 'Monitored workers', data: monitoredData, borderWidth: 1, },
                    {label: 'Measurably exposed workers',data: exposedData, borderWidth: 1, },
                    {label: 'Workers above 1 mSv',data: above1Data,borderWidth: 1,},
        ],
    };

    const frame = {
        "Country": labelsData,
        "Monitored workers": monitoredData,
        "Measurably exposed workers": exposedData,
        "Workers above 1 mSv": above1Data
    };

    return {datas: data, frame: frame, exist: true};
        
}

