export const styles = { 
    container : {
    width: "100%", 
    padding: "50px", 
    backgroundColor: "#F0F0F2", 
    borderRadius: "15px", 
    justifyContent: "center", 
    alignItems: "center", 
    border: "1px solid #70726e", 
    display: "flex", 
    flexDirection: "column" ,
    marginTop: "10px"
    },
    button : {
        height: '55px', 
        fontSize: '1.0rem',
        justifyContent: "flex-start",
        textAlign: "left",
        width: '300px', 
        border: '1px solid #cecece'
    }
};
