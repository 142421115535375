import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
//import ReactMarkdown from 'react-markdown';
import parse from "html-react-parser";
import React from "react";
import '../../styles/CountryPage.css';
import * as countryService from '../../services/country/countryService';

function CountryPage() {

	const { id } = useParams();
	const [countryData, setCountryData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {

		countryService.fetchCountryData(id)
		.then((response)=> response.json())
		.then((data) => setCountryData(data))
		.catch((err)=> {
			console.log("Erreur lors de la recherche des locataires", err);
		})
		.finally(()=>{
			setLoading(false);
		});

	}, [id]);

	if (loading) {
		return <p>Loading...</p>;
	}

	if (!countryData) {
		return <p>No data available for this country.</p>;
	}

	return (
		<div className="country-page">
			{parse(countryData)}
		</div>
  );
}

export default CountryPage;