
import { getApiBaseUrl } from "../util";

export function findAll(token, salt) {

    const url = getApiBaseUrl() + '/api/files';

    const headers = {
        'authorization': `Bearer ${token}`,
        'x-salt': salt,
    };

    return fetch(url, {
        method: 'POST',
        headers: headers,
    });
}