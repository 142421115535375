import { getApiBaseUrl } from "../util";

export function fetchCountryData(id) {

    const url = getApiBaseUrl() + '/api/countryfulldata?sel_country=' + id;

    return fetch(url,
    {
        method: 'get'
    });
}

export function findAll() {

    const url = getApiBaseUrl() + '/api/countries';
    return fetch(url, {method: 'get'});    
}
