import Grid from "@mui/material/Unstable_Grid2/Grid2";
import BoxChart from "./chartComponents/BoxChart";
import Chart101 from "./chartComponents/Chart101";
import Chart102 from "./chartComponents/Chart102";
import Chart02 from "./chartComponents/Chart02";
import Chart03 from "./chartComponents/Chart03";
import Chart04 from "./chartComponents/Chart04";
import Chart05 from "./chartComponents/Chart05";
import Chart06 from "./chartComponents/Chart06";
import * as valuesSelectService from '../../services/initialization/valuesSelectService';
import * as chart01Service from '../../services/chartsData/chart01Service';
import * as chart02Service from '../../services/chartsData/chart02Service';
import * as chart03Service from '../../services/chartsData/chart03Service';
import * as chart04Service from '../../services/chartsData/chart04Service';
import * as chart05Service from '../../services/chartsData/chart05Service';
import * as chart06Service from '../../services/chartsData/chart06Service';
import * as downloadService from '../../services/chartsData/downloadService';
import { useState, useEffect } from "react";

function Charts() {

    const [countries, setCountries] = useState([]);
    const [years, setYears] = useState([]);
    const [occupations, setOccupations] = useState([]);
    const [datasChart01, setDatasChart01] = useState({datas1:{}, frame1: {}, exist1: false, datas2:{}, frame2: {}, exist2: false});
    const [datasChart02, setDatasChart02] = useState({datas:{}, frame: {}, exist: false});
    const [datasChart03, setDatasChart03] = useState({datas:{}, frame: {}, exist: false});
    const [datasChart04, setDatasChart04] = useState({datas:{}, frame: {}, exist: false});
    const [datasChart05, setDatasChart05] = useState({datas:{}, frame: {}, exist: false});
    const [datasChart06, setDatasChart06] = useState({datas:{}, frame: {}, exist: false});

    const [valueSelectedTab, setValueSelectedTab] = useState([]);       // on s'en sert uniquement pour le nom d'export

    useEffect(()=>{
		valuesSelectService.findCountries()
			.then((response) => response.json()).then((data) => {
                const countries = [{ id: 0, name: "All countries" }, ...data];
                setCountries(countries)
            })
			.catch ((err)=>{
				console.error(err)
			});
        valuesSelectService.findYears()
			.then((response) => response.json()).then((data) => setYears({ data }))
			.catch ((err)=>{
				console.error(err)
			});
        valuesSelectService.findOccupations()
			.then((response) => response.json()).then((data) => setOccupations(data))
			.catch ((err)=>{
				console.error(err)
			});            
	}, []);

    const isNonEmptyArray = (arr) => Array.isArray(arr) && arr.length > 0;

    useEffect(() => {
        //if (years && years.data && countries && countries.length > 0 && occupations && occupations.length > 0) {
        if (years?.data && isNonEmptyArray(countries) && isNonEmptyArray(occupations)) {

            const year = years.data[0].year;
            const country = countries[0].id;
            const occupation = occupations[0].id;

            const valueSelected = {year: year, country: country, occupation: occupation};
            updateChart01(valueSelected);
            updateChart02(valueSelected);
            updateChart03(valueSelected);
            updateChart04(valueSelected);
            updateChart05(valueSelected);
            updateChart06(valueSelected);

            setValueSelectedTab([valueSelected, valueSelected, valueSelected, valueSelected, valueSelected, valueSelected]);
        }
    }, [years, countries, occupations]);

    // Construit le nom du fichier en fonction du nom + de l'objet Sélectionné
    const getExportFileName = (name, index, doYear, doCountry, doOccupation) => {

        const valueSelected = valueSelectedTab[index];
        let n = name;

        if (doCountry) {
            n += "_" + countries[valueSelected.country].name;
        }

        if (doYear) {       
            n += "_" + valueSelected.year;
        }

        if (doOccupation) {
            n += "_" + occupations[valueSelected.occupation -1].label;
        }        

        n += ".csv";
        return n;
    }

    const updateTabValueSelected = (index, newValue) => {
        
        const t = [...valueSelectedTab];
        t[index] = newValue;
        setValueSelectedTab(t);        
    }

    const updateChart01 = (valueSelected) => {

        updateTabValueSelected(0, valueSelected);

        chart01Service.getDataMonitored(valueSelected.country, valueSelected.year)
            .then((response) => response.json())
            .then((measures) => {

                const obj1 = chart01Service.getObjectChart101(measures);

                chart01Service.getDataTotalHsv(valueSelected.country, valueSelected.year)
                    .then((response) => response.json())
                    .then((measures) => {
                        
                        const obj2 = chart01Service.getObjectChart102(measures);
                        const obj = { datas1:obj1.datas, frame1: obj1.frame, exist1: obj1.exist, 
                                      datas2:obj2.datas, frame2: obj2.frame, exist2: obj2.exist};

                        setDatasChart01(obj);
                    })
                    .catch((err) => {
                        console.error(err);
                    });                
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const downloadChart01 = () => {

        datasChart01.frame1["Collective dose (man.Sv)"] = datasChart01.frame2["Collective dose (man.Sv)"];
        const csvdata = downloadService.csvmaker(datasChart01.frame1);
        downloadService.download(csvdata, getExportFileName("Monitored_workers_and_collective_dose_per_field", 0, true, true, false));
    }

    const updateChart02 = (valueSelected) => {

        updateTabValueSelected(1, valueSelected);

        chart02Service.getData(valueSelected.year)
            .then((response) => response.json())
            .then((measures) => setDatasChart02(chart02Service.getObject(measures)))
            .catch((err) => {
                console.error(err);
            });
    }

    const downloadChart02 = () => {
        const csvdata = downloadService.csvmaker(datasChart02.frame);
        downloadService.download(csvdata, getExportFileName("Monitored_and_measurably_exposed_workers_per_country", 1, true, false, false));
    }

    const updateChart03 = (valueSelected) => {

        updateTabValueSelected(2, valueSelected);

        chart03Service.getData(valueSelected.year)
            .then((response) => response.json())
            .then((measures) => setDatasChart03(chart03Service.getObject(measures)))
            .catch((err) => {
                console.error(err);
            });
    }    

    const downloadChart03 = () => {
        const csvdata = downloadService.csvmaker(datasChart03.frame);
        downloadService.download(csvdata, getExportFileName("Average_individual_dose_per_country_and_average_values_over_all_countries", 2, true, false, false));
    }    

    const updateChart04 = (valueSelected) => {

        updateTabValueSelected(3, valueSelected);
        chart04Service.getData(valueSelected.country)
            .then((response) => response.json())
            .then((measures) => setDatasChart04(chart04Service.getObject(measures)))
            .catch((err) => {
                console.error(err);
            });
    }
    const downloadChart04 = () => {
        const csvdata = downloadService.csvmaker(datasChart04.frame);
        downloadService.download(csvdata, getExportFileName("Monitored_and_measurably_exposed_workers_over_years", 3, false, true, false));
    }  

    const updateChart05 = (valueSelected) => {

        updateTabValueSelected(4, valueSelected);
        chart05Service.getData(valueSelected.country)
            .then((response) => response.json())
            .then((measures) => setDatasChart05(chart05Service.getObject(measures)))
            .catch((err) => {
                console.error(err);
            });
    }
    const downloadChart05 = () => {
        const csvdata = downloadService.csvmaker(datasChart05.frame);
        downloadService.download(csvdata, getExportFileName("Average_dose_over_years", 4, false, true, false));
    }

    const updateChart06 = (valueSelected) => {

        updateTabValueSelected(5, valueSelected);
        chart06Service.getData(valueSelected.country, valueSelected.occupation)
            .then((response) => response.json())
            .then((measures) => setDatasChart06(chart06Service.getObject(measures)))
            .catch((err) => {
                console.error(err);
            });
    }
    const downloadChart06 = () => {
        const csvdata = downloadService.csvmaker(datasChart06.frame);
        downloadService.download(csvdata, getExportFileName("Monitored_and_measurably_exposed_workers_combined_with_average_individual_dose", 5, false, true, true));
    }    

    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <BoxChart   title="Monitored workers and collective dose per field" 
                                showSelectCountry={true} showSelectYear={true} showSelectOccupation={false} 
                                years={years} countries={countries} occupations={occupations}
                                action={updateChart01} actionDownload={downloadChart01} >
                        <Grid container spacing={2}>
                            <Grid xs={6}>
                                <Chart101 datas={datasChart01} />
                            </Grid>
                            <Grid xs={6}>
                                <Chart102 datas={datasChart01} />
                            </Grid>                            
                        </Grid>
                    </BoxChart>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <BoxChart   title="Monitored and measurably exposed workers per country" 
                                showSelectCountry={false} showSelectYear={true} showSelectOccupation={false} 
                                years={years} countries={countries} occupations={occupations}
                                action={updateChart02} actionDownload={downloadChart02} >
                        <Chart02 datas={datasChart02} />
                    </BoxChart>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <BoxChart   title="Average individual dose per country and average values over all countries" 
                                showSelectCountry={false} showSelectYear={true} showSelectOccupation={false} 
                                years={years} countries={countries} occupations={occupations}
                                action={updateChart03} actionDownload={downloadChart03}  >
                        <Chart03 datas={datasChart03} />
                    </BoxChart>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <BoxChart   title="Monitored and measurably exposed workers over years" 
                                showSelectCountry={true} showSelectYear={false} showSelectOccupation={false} 
                                years={years} countries={countries} occupations={occupations}
                                action={updateChart04} actionDownload={downloadChart04}>
                        <Chart04 datas={datasChart04} />
                    </BoxChart>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <BoxChart   title="Average dose over years" 
                                showSelectCountry={true} showSelectYear={false} showSelectOccupation={false} 
                                years={years} countries={countries} occupations={occupations}
                                action={updateChart05} actionDownload={downloadChart05}>
                        <Chart05 datas={datasChart05} />
                    </BoxChart>
                </Grid>
            </Grid>        
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <BoxChart   title="Monitored and measurably exposed workers combined with average individual dose" 
                                showSelectCountry={true} showSelectYear={false} showSelectOccupation={true} 
                                years={years} countries={countries} occupations={occupations}
                                action={updateChart06} actionDownload={downloadChart06}>
                        <Chart06 datas={datasChart06} />
                    </BoxChart>
                </Grid>
            </Grid>                 
        </>
    );
}

export default Charts;
